import {
  EPaymentEvents,
  type IPaymentOfferClickedEvent,
  type IPaymentInputEnteredEvent,
  type IPaymentPaymentCompletedEvent,
  type IPaymentPaymentFinishedEvent,
  type IPaymentPaymentOpenedEvent,
  type IPaymentPaymentStartedEvent,
  type IPaymentPresetButtonClickedEvent,
  type IPaymentSliderMovedEvent,
  type IPaymentPromoEnteredEvent,
  type IPaymentPromoResetEvent,
} from '../types/payment.types';

export class PaymentEvents {
  static dealClicked() {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EPaymentEvents.DEAL_CLICKED,
    });
  }

  static paymentCompleted(event: IPaymentPaymentCompletedEvent) {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EPaymentEvents.PAYMENT_COMPLETED,
      eventProperties: event,
    });
  }

  static paymentFinished(event: IPaymentPaymentFinishedEvent) {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EPaymentEvents.PAYMENT_FINISHED,
      eventProperties: event,
    });
  }

  static paymentOpened(event: IPaymentPaymentOpenedEvent) {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EPaymentEvents.PAYMENT_OPENED,
      eventProperties: event,
    });
  }

  static paymentStarted(event: IPaymentPaymentStartedEvent) {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EPaymentEvents.PAYMENT_STARTED,
      eventProperties: event,
    });
  }

  static presetButtonClicked(event: IPaymentPresetButtonClickedEvent) {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EPaymentEvents.PRESET_BUTTON_CLICKED,
      eventProperties: event,
    });
  }

  static promoEntered(event: IPaymentPromoEnteredEvent) {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EPaymentEvents.PROMO_ENTERED,
      eventProperties: event,
    });
  }

  static promoReset(event: IPaymentPromoResetEvent) {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EPaymentEvents.PROMO_RESET,
      eventProperties: event,
    });
  }

  static offerClicked(event: IPaymentOfferClickedEvent) {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EPaymentEvents.OFFER_CLICKED,
      eventProperties: event,
    });
  }

  static sliderMoved(event: IPaymentSliderMovedEvent) {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EPaymentEvents.SLIDER_MOVED,
      eventProperties: event,
    });
  }

  static inputEntered(event: IPaymentInputEnteredEvent) {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EPaymentEvents.INPUT_ENTERED,
      eventProperties: event,
    });
  }
}
