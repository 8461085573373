export enum EPaymentEvents {
  DEAL_CLICKED = 'Payment - Deal Clicked',
  PAYMENT_COMPLETED = 'Payment - Payment Completed',
  PAYMENT_FINISHED = 'Payment - Payment Finished',
  PAYMENT_OPENED = 'Payment - Payment Opened',
  PAYMENT_STARTED = 'Payment - Payment Started',
  PRESET_BUTTON_CLICKED = 'Payment - Preset Button Clicked',
  OFFER_CLICKED = 'Payment - Offer Clicked',
  SLIDER_MOVED = 'Payment - Slider Moved',
  INPUT_ENTERED = 'Payment - Input Entered',
  PROMO_ENTERED = 'Payment - Promo Entered',
  PROMO_RESET = 'Payment - Promo Reset',
}

export interface IPaymentPaymentCompletedEvent {
  '$revenue': number;
  'Payment Currency': string;
  'Payment Id': string;
  'Payment Method': string;
  'Payment Sum': number;
  'Payment Type': string;
  'User Currency': string;
}

export interface IPaymentPaymentFinishedEvent {
  'Payment Currency': string;
  'Payment Id': string;
  'Payment Method': string;
  'Payment Sum': number;
  'Payment Type': string;
  'User Currency': string;
}

export enum EPaymentOpenedButtonPlaces {
  HEADER = 'header',
  PROFILE = 'profile',
  SECRET_SHOP = 'secret_shop',
  HAMBURGER_MENU = 'hamburger menu',
  PROMO_POPUP = 'promo pop-up',
  EVENT = 'event',
  CASE = 'case',
  SUCCESS_PAGE = 'success_page',
  UNSUCCESS_PAGE = 'unsuccess_page',
  NEW_CASES_BANNER = 'new_cases_banner',
  WAITING_PAGE = 'waiting_page',
  WITHDRAW_HISTORY = 'WIthdraw History',
  FOOTER = 'footer',
}

export interface IPaymentPaymentOpenedEvent {
  buttonPlace: EPaymentOpenedButtonPlaces;
}

export interface IPaymentPaymentStartedEvent {
  'Payment Currency': string;
  'Payment Id': string;
  'Payment Method': string;
  'Payment Sum': number;
  'Payment Type': string;
  'User Currency': string;
}

export interface IPaymentPresetButtonClickedEvent {
  'Button Value': number;
}

export interface IPaymentPromoEnteredEvent {
  'Promo Name': string;
  'Promo Type': string;
}

export interface IPaymentPromoResetEvent {
  'Promo Name': string;
  'Promo Type': string;
}

export interface IPaymentOfferClickedEvent {
  'Offer Price': number;
  'Offer Name': string;
}

export interface IPaymentSliderMovedEvent {
  'Slider Sum': number;
}

export interface IPaymentInputEnteredEvent {
  'Input Sum': number;
}
